import { useRouter } from 'next/router';

const LOCALES_MAP = {
  en_US: 'en',
  en_CA: 'en',
  fr_CA: 'fr',
};

export const useLocaleCode = (): string => {
  const { locale } = useRouter();
  return LOCALES_MAP[locale] || 'en';
};
