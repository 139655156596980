import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Box, Collapse, Drawer, DrawerContent, DrawerOverlay, List } from '@chakra-ui/react';
import { useComposable } from 'composable';
import { ShoppingCategoriesOpenProps, useCheckoutContext } from 'composable/components/checkout/context';
import { ChildrenMenuItem } from 'composable/components/cms-components/left-side-menu/children-menu-item';
import { MenuItem } from 'composable/components/cms-components/left-side-menu/menu-item';
import { Nav } from 'composable/components/cms-components/left-side-menu/nav';
import { ShopCategories } from 'composable/components/cms-components/shop-categories-left-side-menu';
import { FEATURE_FLAG_DISPLAY_SHOP_CATEGORIES_SIDE_MENU_OPTION } from 'composable/components/general';
import { PayBillButton } from 'composable/components/pay-bill-button';
import { ButtonLinks } from './button-links';
import { usePropsMenu } from 'composable/helpers/hooks/usePropsMenu';
import { selectedItemCb } from './utils';
import { SideMenuItem } from 'composable/types';

export interface LeftSideMenuProps {
  links: SideMenuItem[];
}

//Constants
//TODO - Move to constants file and check references in other components
export const leftSideMenuWidth = '246px';
export enum LeftSideMenuLabels {
  Home = 'Home',
  OrderGuides = 'Order Guides',
  Catalog = 'Catalog',
  Orders = 'Orders',
  Accounts = 'Accounts',
  PayBill = 'Pay Bill',
  MyShamrock = 'MyShamrock',
  ShamrockORDERS = 'ShamrockORDERS',
  ShopCategories = 'Shop Categories',
}
export const excludedLabesOpenNewWindow = [
  LeftSideMenuLabels.ShamrockORDERS,
  LeftSideMenuLabels.MyShamrock,
  LeftSideMenuLabels.PayBill,
];
export const excludedShopCategoriesLabel = [LeftSideMenuLabels.ShopCategories];
export const excludedLabels = [...excludedLabesOpenNewWindow, ...excludedShopCategoriesLabel];
// Buttons that are displayed in public pages

export const LeftSideMenu = ({ links }: LeftSideMenuProps) => {
  const { shoppingCategoriesOpen, closeShoppingCategories } = useCheckoutContext();

  const router = useRouter();
  const { megaDrawer } = useComposable();

  const { openShoppingCategories } = useCheckoutContext();

  const { openItems, setOpenItems, buttons, finalLinks } = usePropsMenu(links);

  // Set selected item based on URL
  const selectedItem = useMemo(() => selectedItemCb(router.asPath), [router.asPath]);

  const toggleOpen = useCallback((label: string) => {
    setOpenItems((prev) => ({ ...prev, [label]: !prev[label] }));
  }, []);

  const isSelected = useCallback(
    (path: string) => {
      return path === selectedItem;
    },
    [selectedItem],
  );

  return (
    <>
      {FEATURE_FLAG_DISPLAY_SHOP_CATEGORIES_SIDE_MENU_OPTION && (
        <Drawer
          placement="left"
          onClose={closeShoppingCategories}
          isOpen={shoppingCategoriesOpen === ShoppingCategoriesOpenProps.LEFT_SIDE}
        >
          <DrawerOverlay css={{ background: 'rgba(0, 0, 0, .7)' }} />
          <DrawerContent
            minW={{ base: '339px', lg: '400px' }}
            maxW={{ base: '339px', lg: '400px' }}
            style={ShopCategories.DrawerStyle}
            {...ShopCategories.DrawerCSS}
          >
            <ShopCategories />
          </DrawerContent>
        </Drawer>
      )}
      <Nav leftSideMenuWidth={leftSideMenuWidth}>
        <ButtonLinks links={buttons} />
        <List>
          {finalLinks.map((item) => {
            switch (item.menuType) {
              case 'payBillButton':
                return <PayBillButton title={item.label} key={`payBillButton_${item.label}`} />;
              default:
                return (
                  <React.Fragment key={item.label}>
                    <MenuItem
                      item={item}
                      locale={router.locale || router.defaultLocale}
                      onClick={(e) => {
                        if (excludedLabesOpenNewWindow.includes(item.label as LeftSideMenuLabels)) {
                          e.preventDefault();
                          window.open(item.link.type === 'link' ? item.link.link : item.link.pageFolder?._url);
                          return;
                        }
                        if (item.label === LeftSideMenuLabels.Accounts) {
                          e.preventDefault();
                          item.children && toggleOpen(item.label);
                          return;
                        }
                        item.children && toggleOpen(item.label);
                      }}
                      isAccountOpened={
                        item.label === LeftSideMenuLabels.Accounts &&
                        openItems.hasOwnProperty(LeftSideMenuLabels.Accounts) &&
                        openItems[LeftSideMenuLabels.Accounts] === true
                      }
                    />
                    {item.children && (
                      <Box as="li">
                        <Collapse in={openItems[item.label]}>
                          <List>
                            {item.children.map((child) => (
                              <ChildrenMenuItem
                                item={child}
                                key={child.label}
                                isSelected={isSelected}
                                onClick={() => {
                                  if (child.label === LeftSideMenuLabels.ShopCategories) {
                                    openShoppingCategories(
                                      megaDrawer.isOpen
                                        ? ShoppingCategoriesOpenProps.DRAWER
                                        : ShoppingCategoriesOpenProps.LEFT_SIDE,
                                    );
                                    return;
                                  }
                                }}
                              />
                            ))}
                          </List>
                        </Collapse>
                      </Box>
                    )}
                  </React.Fragment>
                );
            }
          })}
        </List>
      </Nav>
    </>
  );
};
