import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Box } from '@chakra-ui/react';
import { useFormat } from 'helpers/hooks';

interface NavProps {
  leftSideMenuWidth: number | string;
}

export const Nav: FunctionComponent<PropsWithChildren<NavProps>> = ({ leftSideMenuWidth, children }) => {
  const { formatMessage } = useFormat({ name: 'common' });

  return (
    <aside>
      <Box
        as="nav"
        aria-label={formatMessage({ id: 'nav.sidebar.label' })}
        display={{ base: 'none', xl: 'block' }}
        width={{ lg: leftSideMenuWidth }}
        maxWidth={{ lg: leftSideMenuWidth }}
        height="100vh"
        minHeight="100vh"
        padding="72px 0"
        bgColor="neutral.700"
        position="fixed"
        top="0"
        left="0"
        zIndex="900"
        overflowY="auto"
        className="left-side-menu"
        __css={{
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            width: '6px',
            backgroundColor: '#6C6D75',
            borderRadius: '4px',
            marginBottom: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: '#C8C9D0',
          },
        }}
      >
        {children}
      </Box>
    </aside>
  );
};
