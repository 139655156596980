import { Result } from '@Types/product/Result';
import { mutate } from 'swr';
import { fetchApiHub } from 'frontastic';
import { PaginatedQuery } from '@Types/query/PaginatedQuery';
import { appendQueryParamsToBaseUrl } from 'helpers/utils/append-query-params-to-base-url';
import { GENERIC_TOAST_ERROR_ID, TOAST_ICON } from 'composable/components/general';
import { useToast } from '@chakra-ui/react';

export interface CategoriesQuery extends PaginatedQuery {
  skus?: string[];
  sort?: string;
  withTotal?: boolean;
  where?: string;
}

const BASE_URL = `/action/product/queryCategories`;

export const queryCategories = async (
  toast: ReturnType<typeof useToast>,
  toastMessage: string,
  queryParams?: CategoriesQuery,
): Promise<Result> => {
  try {
    const updatedRoute = appendQueryParamsToBaseUrl(BASE_URL, queryParams);

    const res = (await fetchApiHub(updatedRoute, {
      method: 'POST',
    })) as Result;

    mutate(BASE_URL, res, { revalidate: false });

    return res;
  } catch (error) {
    console.error('Error API: queryCategories', error);
    if (!toast.isActive(GENERIC_TOAST_ERROR_ID)) {
      toast({
        duration: 5000,
        status: 'error',
        title: toastMessage,
        id: GENERIC_TOAST_ERROR_ID,
        icon: TOAST_ICON.error,
      });
    }

    throw error;
  }
};
