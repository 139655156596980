import React from 'react';
import NextLink from 'next/link';
import { LinkReferenceType } from 'composable/types';

interface LinkReference {
  type: LinkReferenceType.link;
  link: string;
  target?: string;
  openInNewWindow: boolean;
}

interface PageFolderReference {
  type: LinkReferenceType.pageFolder;
  pageFolder: {
    pageFolderId: string;
    name: string;
    hasLivePage: boolean;
    _urls: {
      [locale: string]: string;
    };
    _url: string;
  };
  openInNewWindow: boolean;
}

export type Reference = LinkReference | PageFolderReference;

export const getReferenceTarget = (target: Reference): string => {
  switch (target?.type) {
    case LinkReferenceType.link:
      return target.link || target.target;
    case LinkReferenceType.pageFolder:
      return target.pageFolder._url;
    default:
      //Log.warn('Reference ', target, ' is not valid reference')
      return '/';
  }
};

export function getTargetProps(target: LinkReference | PageFolderReference) {
  if (target.openInNewWindow) {
    return {
      target: '_blank',
      rel: 'noopener',
    };
  }

  return {};
}

export function isLiveReference(reference: LinkReference | PageFolderReference) {
  return (
    reference.type !== LinkReferenceType.pageFolder ||
    (reference.type === LinkReferenceType.pageFolder && reference.pageFolder?.hasLivePage)
  );
}

interface Props {
  className?: string;
  target: Reference;
  ariaLabel?: string;
  children: React.ReactNode;
}

export const ReferenceLink: React.FC<Props> = ({ target, className, ariaLabel, children }) => {
  //no valid target for next/link
  if (!target)
    return (
      <NextLink href="#">
        <a aria-label={ariaLabel} className={className}>
          {children}
        </a>
      </NextLink>
    );

  return (
    <NextLink href={getReferenceTarget(target)}>
      <a aria-label={ariaLabel} className={className} {...getTargetProps(target)}>
        {children}
      </a>
    </NextLink>
  );
};
