import { Flex, Icon, Text, useToken } from '@chakra-ui/react';
import NextLink from 'next/link';
import { CategoryHierarchyItem } from 'composable/helpers/utils/build-category-hierarchy';
import { useFormat } from 'helpers/hooks/useFormat';
import { CaretRight } from 'phosphor-react';

type ItemProps = {
  label: string;
  onClick: () => void;
  children: CategoryHierarchyItem[];
  url: string;
};

export const Item = ({ label, onClick, children, url }: ItemProps) => {
  const primary300 = useToken('colors', 'primary.300');
  const { formatMessage } = useFormat({ name: 'common' });
  const defaultBrowseAllTerm = formatMessage({ id: 'label.browseAll' });
  const isDefaultBrowserLabel = label?.toLowerCase()?.includes(defaultBrowseAllTerm?.toLowerCase());
  const showIcon = children?.length > 0 || isDefaultBrowserLabel === true;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onClick(); 
    }
  };

  return (
    <Flex
      as="li"
      h="8"
      minH="8"
      gap={2}
      cursor="pointer"
      w="fit-content"
      onClick={onClick}
      onKeyDown={handleKeyDown}
      color="neutral.200"
      display="flex"
      alignItems="center"
      tabIndex={1}
      _hover={{ color: 'primary.500' }}
      _focusVisible={{ outline: `1px solid ${primary300}`, borderRadius: '4px' }}
      lineHeight="19.2px"
    >
      {children.length === 0 ? (
        <NextLink href={url} passHref>
          <Text
            as="a"
            textAlign="left"
            fontWeight="normal"
            lineHeight="19.2px"
            display="flex"
            alignItems="start"
            gap={1}
            _focusVisible={{ outline: `1px solid ${primary300}`, borderRadius: '4px' }}
          >
            {label} {showIcon && <Icon w={5} h={5} as={CaretRight} />}
          </Text>
        </NextLink>
      ) : (
        <Text textAlign="left" fontWeight="normal" lineHeight="19.2px" display="flex" alignItems="start" gap={1}>
          {label} {showIcon && <Icon w={5} h={5} as={CaretRight} />}
        </Text>
      )}
    </Flex>
  );
};
