import { CategoriesQuery } from 'frontastic/actions/category';

export function appendQueryParamsToBaseUrl(baseUrl: string, params: CategoriesQuery): string {
  if (!params) {
    return baseUrl;
  }

  const queryParams: string[] = Object.entries(params)
    .filter(([_, value]) => value !== undefined)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  if (queryParams.length > 0) {
    return `${baseUrl}?${queryParams.join('&')}`;
  }

  return baseUrl;
}
