import { PageDataResponse, PagePreviewDataResponse, Tastic } from 'frontastic/lib/types';

export function findTasticByType(data: PageDataResponse & PagePreviewDataResponse, tasticType: string): Tastic | null {
  const page = data?.page;

  for (const sectionKey in page.sections) {
    const section = page.sections[sectionKey];

    for (const layoutElement of section.layoutElements) {
      for (const tastic of layoutElement.tastics) {
        // Check if the tasticType matches the one we're looking for
        if (tastic.tasticType === tasticType) {
          return tastic;
        }
      }
    }
  }

  return null;
}

export function findMultipleTasticsByType(
  data: PageDataResponse & PagePreviewDataResponse,
  tasticType: string,
): Tastic[] | null {
  const page = data?.page;
  const tastics = [];

  for (const sectionKey in page.sections) {
    const section = page.sections[sectionKey];

    for (const layoutElement of section.layoutElements) {
      for (const tastic of layoutElement.tastics) {
        // Check if the tasticType matches the one we're looking for, for multiple
        if (tastic.tasticType === tasticType) {
          tastics.push(tastic);
        }
      }
    }
  }

  return tastics.length > 0 ? tastics : null;
}
