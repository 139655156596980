import { FunctionComponent } from 'react';
import NextLink from 'next/link';
import { Box, Button, Icon, Link, useBreakpointValue, useToken } from '@chakra-ui/react';
import { useGlobal } from 'components/globalProvider';
import { ArrowUpRight } from 'phosphor-react';

type PayBillButtonProps = {
  title: string;
};

export const PayBillButton: FunctionComponent<PayBillButtonProps> = ({ title }) => {
  const { activeWarehouse, shamrockUser } = useGlobal().useUserGlobal.state;
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const neutral700 = useToken('colors', 'neutral.700');
  const neutral800 = useToken('colors', 'neutral.800');

  const gradientBorderBg = useBreakpointValue({ base: neutral800, xl: neutral700 });

  if (!activeWarehouse?.payBillUrl) {
    return null;
  }

  return (
    <Box as="li" my={{ base: 4, lg: 5 }} mx={{ base: 8, lg: 10 }}>
      <Link as={NextLink} target="_blank" href={activeWarehouse.payBillUrl} isExternal passHref>
        <Button
          as="a"
          cursor="pointer"
          target="_blank"
          size={isMobile ? 'ds-lg' : 'ds-xl'}
          bgColor={gradientBorderBg}
          variant="ds-ghost"
          rightIcon={<Icon as={ArrowUpRight} />}
        >
          {title}
        </Button>
      </Link>
    </Box>
  );
};
