import { SWR_OPTIONS } from 'composable/components/order-guide/helpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useLocaleCode } from 'helpers/hooks/useLocaleCode';
import useSWR from 'swr';
import { queryAllCategories } from 'frontastic/actions/category';
import { buildCategoryHierarchy } from '../utils/build-category-hierarchy';
import { useToast } from '@chakra-ui/react';

export const useGetCategories = () => {
  const locale = useLocaleCode();
  const toast = useToast();
  const { formatMessage } = useFormat({ name: 'common' });
  const defaultParentName = formatMessage({ id: 'label.categories' });
  const defaultBrowseAllTerm = formatMessage({ id: 'label.browseAll' });
  const defaultSeeAllTerm = formatMessage({ id: 'label.seeAll' });

  const { data, isValidating, isLoading } = useSWR(
    ['useGetCategories', locale],
    async () => {
      const response = await queryAllCategories(toast, formatMessage({ id: 'app.generic.error' }));
      return buildCategoryHierarchy(response, locale, defaultParentName, defaultBrowseAllTerm, defaultSeeAllTerm);
    },
    {
      ...SWR_OPTIONS,
      errorRetryCount: 2,
    },
  );

  return { categories: data, isLoading: isLoading || isValidating };
};
