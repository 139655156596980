/* eslint-disable import/no-unresolved */
import Head from 'next/head';
import { VStack, Spinner, Image, Center } from '@chakra-ui/react';
import { useFormat } from 'helpers/hooks/useFormat';

export const FullPageLoader = () => {
  const { formatMessage } = useFormat({ name: 'common' });
  return (
    <>
      <Head>
        <title>{formatMessage({ id: 'app.title' })}</title>
      </Head>
      <Center h="100vh" bg="neutral.800">
        <VStack spacing="40px">
          <Image
            src="/images/Shamrock-new-logo.svg"
            alt={formatMessage({ id: 'navigation.logo.altText' })}
            boxSize="200px"
            height="119.322px"
          />
          <Spinner size="xl" thickness="4px" speed="0.65s" color="primary.500" />
        </VStack>
      </Center>
    </>
  );
};
