import { Category } from '@Types/product/Category';
import { getLocalizedProperty } from 'composable/helpers/utils/get-localized-property';
import routes from 'helpers/constants/routes';

export interface CategoryHierarchyItem {
  id: string;
  name: string;
  children: CategoryHierarchyItem[];
  url: string;
}

export const buildCategoryHierarchy = (
  items: Category[],
  locale: string,
  defaultParentName: string = 'Categories',
  defaultBrowseAllTerm: string = 'Browse All',
  defaultSeeAllTerm: string = 'See All',
): CategoryHierarchyItem[] => {
  function assignChildren(parentId: string, currentParentName = defaultParentName, parentUrl = '', depth = 0) {
    if (depth > 1) {
      return [];
    }

    let children = items
      .filter((item) => item.name !== '[ORM] Bakery')
      .filter((item) => item?.parent?.id === parentId)
      .map((item) => {
        const name = getLocalizedProperty(item?.ancestors[0]?.obj?.name, locale);
        const categoryUrl = `/category/${encodeURIComponent(item?.name)}`;
        const subCategoryUrl = `/category/${encodeURIComponent(name)} > ${encodeURIComponent(item?.name)}`;
        const itemUrl = item.ancestors.length > 0 ? subCategoryUrl : categoryUrl;
        const childItems = assignChildren(item.id, item.name, itemUrl, depth + 1);

        return { ...item, url: itemUrl, children: childItems };
      });

    children.sort((a, b) => a.orderHint.toLowerCase().localeCompare(b.orderHint.toLowerCase()));

    if (children.length > 0) {
      const isDefault = defaultParentName === currentParentName;
      const name = `${isDefault ? defaultParentName : currentParentName}`;

      const customItem: CategoryHierarchyItem = {
        id: `custom_${defaultParentName}`,
        name: `${depth === 0 ? defaultBrowseAllTerm : defaultSeeAllTerm} ${name}`,
        url: isDefault ? routes.CATALOG_CATEGORIES_ALL : parentUrl,
        children: [],
      };

      children.unshift(customItem);
    }

    return children;
  }

  // Start the recursive process with top-level items (those without a parent)
  return assignChildren(undefined);
};
