import { Category } from '@Types/product/Category';
import { useToast } from '@chakra-ui/react';
import { queryCategories } from 'frontastic/actions/category/queryCategory';

export const queryAllCategories = async (toast: ReturnType<typeof useToast>, toastMessage: string) => {
  let shouldContinue = true;
  let nextCursor = null;
  const allCategories: Category[] = [];

  while (shouldContinue) {
    const queryParams = {
      limit: 500,
      expand: ['ancestors[*]'],
      ...(nextCursor && { cursor: nextCursor }),
    };

    try {
      const response = await queryCategories(toast, toastMessage, queryParams);
      if (response && response.items && response.items.length > 0) {
        allCategories.push(...(response.items as Category[]));

        // Check if we should continue based on the presence of a nextCursor
        shouldContinue = !!response.nextCursor;
        nextCursor = response.nextCursor;
      } else {
        shouldContinue = false;
      }
    } catch (error) {
      console.error('Failed to fetch all categories:', error);
      shouldContinue = false;
    }
  }

  return allCategories;
};
