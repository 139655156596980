import { Flex, Icon, IconButton, Text, useToken } from '@chakra-ui/react';
import { useFormat } from 'helpers/hooks/useFormat';
import { CaretLeft, X as CloseIcon } from 'phosphor-react';

type HeaderProps = {
  category: string;
  onClick: () => void;
  onClose: () => void;
};

export const Header = ({ category, onClick, onClose }: HeaderProps) => {
  const primary300 = useToken('colors', 'primary.300');
  const { formatMessage } = useFormat({ name: 'common' });
  return (
    <Flex alignItems="start" justifyContent="space-between">
      <Flex
        tabIndex={1}
        as="button"
        color="white"
        cursor="pointer"
        onClick={onClick}
        alignItems="start"
        _hover={{ color: { base: 'white', lg: 'primary.500' } }}
        _focusVisible={{ outline: `1px solid ${primary300}`, borderRadius: '4px' }}
      >
        <Icon mr={2} w={6} h={6} as={CaretLeft} aria-label={formatMessage({ id: 'caret.left.icon.label' })} />
        <Text as="h2" display="flex" flexWrap="wrap" fontSize="lg" fontWeight="bold" textAlign="left" lineHeight="24px">
          {category}
        </Text>
      </Flex>
      <IconButton
        aria-label={formatMessage({ id: 'navigation.logo.altText' })}
        cursor="pointer"
        color="white"
        onClick={onClose}
        h={6}
        ml={2}
        minW={6}
        maxW={6}
        tabIndex={1}
        background="transparent"
        _active={{ background: 'transparent' }}
        _hover={{ color: { base: 'white', lg: 'primary.500' } }}
        _focusVisible={{ outline: `1px solid ${primary300}`, borderRadius: '4px' }}
        icon={<Icon h={6} w={6} as={CloseIcon} />}
      />
    </Flex>
  );
};
