import { ButtonLabel, SideMenuItem } from 'composable/types';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useFilterAllowedLinks } from '../utils/user-utils';
import { excludedShopCategoriesLabel, LeftSideMenuLabels } from 'composable/components/cms-components';
import { isCatalogSelectedCb } from 'composable/components/cms-components/left-side-menu/utils';

const linksToButtons = [ButtonLabel.LOG_IN_TO_ORDER?.toLowerCase(), ButtonLabel.JOIN_TODAY?.toLowerCase()];

export const usePropsMenu = (links: SideMenuItem[], isDrawer: boolean = false) => {
  const { asPath: path } = useRouter();
  const [openItems, setOpenItems] = useState({});

  const allowedLinks = useFilterAllowedLinks(links);
  const buttons = useMemo(
    () => allowedLinks.filter((link) => linksToButtons.includes(link.label?.toLowerCase())),
    [allowedLinks],
  );

  const finalLinks = useMemo(
    () => allowedLinks.filter((link) => !linksToButtons.includes(link.label?.toLowerCase())),
    [allowedLinks],
  );

  useEffect(() => {
    // Handle the open state for parent items
    const newOpenItems = isDrawer ? { Catalog: true } : {};
    finalLinks.forEach((item) => {
      if (item.children) {
        const isParentPath = path === item.link.link || path === item.link.pageFolder?._url;

        const isCatalogSelected = isCatalogSelectedCb(path);

        if (item.label === LeftSideMenuLabels.Catalog && isCatalogSelected) {
          newOpenItems[item.label] = true;
          return;
        }

        const isChildPathSelected = item.children.some((child) => {
          if (excludedShopCategoriesLabel.includes(child.label as LeftSideMenuLabels) && path === '/') {
            return false;
          }
          if (child.label === LeftSideMenuLabels.ShopCategories && isCatalogSelected) {
            return true;
          }

          return child.link.link === path || child.link.pageFolder?._url === path;
        });
        if (isParentPath || isChildPathSelected) {
          newOpenItems[item.label] = true;
        }
      }
    });
    setOpenItems({ ...newOpenItems });
  }, [path]);

  return { openItems, setOpenItems, buttons, finalLinks };
};
