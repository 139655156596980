import routes from 'helpers/constants/routes';
import { excludedLabels, LeftSideMenuLabels } from '.';

export const isProductPage = (path: string) => /^\/product\/\w+/.test(path);
export const isCategoryPage = (path: string) => /^\/category\/\w+/.test(path);
export const isSearchPage = (path: string) => /^\/search(\?.*)?$/.test(path);
export const isHomePage = (path: string) => /^\/\?code=[^&]+(&.*)?$/.test(path);
export const isCatalogSelectedCb = (path: string) => isProductPage(path) || isCategoryPage(path) || isSearchPage(path);

export const isLabelExcluded = (label: string): boolean => excludedLabels.includes(label as LeftSideMenuLabels);
export const isAccountLabel = (label: string): boolean => LeftSideMenuLabels.Accounts === label;
export const selectedItemCb = (path: string) => {
  if (isCatalogSelectedCb(path)) {
    return routes.CATALOG;
  }
  return isHomePage(path) ? routes.HOME : path;
};
